import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Gallery from '../Components/Gallery';
import { harkany } from '../data/images';


export default function HarkanyHungarian() {
  const target = isMobile ? '' : '_blank';
  const rel = isMobile ? '' : 'noopener noreferrer';


  return (
    <Wrapper>
      <InnerWrapper>
        <GalleryWrapper isMobile={isMobile}>
          <h3>Termálfürdő</h3>
          <p>
          Harkány város termálfürdőjéről több mint 180 éve kiváló    <a href= "https://harkanyfurdo.hu" target={target} rel={rel}>
          gyógyhelyként
            </a>{' '} ismert. A kellemesen meleg víz (34-38 °C) nem csak ellazulásra alkalmas, 
          de különösen hatékony összetevőkben is gazdag. Ez a tény különösen népszerű a 
          nőgyógyászati panaszokkal, csontritkulással, reumával vagy pikkelysömörrel 
          küzdők körében, további előnye, hogy egyes egészségbiztosítók még a fürdő 
          látogatásának költségeit is fedezik. A víz melegének köszönhetően a 
          termálfürdők külső medencéi a téli időszakban is nyitva vannak. A 13,5 
          hektáros területen szaunák, pezsgőfürdők és masszázsmedencék, valamint 
          kozmetikai és masszázsszalon gondoskodik a teljes mértékben sikeres 
          wellness üdülésről. További információért kattintson&nbsp;
          <a
              href= "https://harkanyfurdo.hu/gyogyfurdo/gyogyfurdo" 
              target={target}
              rel={rel}
            >
             ide
            </a>.
          </p>
        </GalleryWrapper>

        <Gallery images={harkany} />

        <GalleryWrapper isMobile={isMobile}>
          <h3>Kalandmedence</h3>
          <p>
          Az üdülőhely nem csak termálfürdőt kínál: májustól októberig számos 
          úszó- és sportmedence és csúszdák állnak rendelkezésre a vendégek számára 
          a gazdagon parkosított területen, a kicsik pedig pancsoló medencékben 
        tudják levezetni mérhetetlen energiájukat. Harkány a vízen túl is tud 
        szórakozást nyújtani: strandröplabda- és focipályák, torna- és 
        sportesemények animátorok vezetésével, különféle éttermek és bárok, 
        valamint koncertek és előadások várják a látogatókat. További információért kattintson
        &nbsp;<a
              href= "https://harkanyfurdo.hu/strand" 
              target={target}
              rel={rel}
            >
             ide
            </a>.
          </p>
        </GalleryWrapper>
        <Gallery images={harkany} />
      </InnerWrapper>
    </Wrapper>
  );
}

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  box-shadow: var(--bs2);
  border-radius: 10px;
  overflow-x: hidden;
  background: var(--clr-bg);
  padding: 50px 0 0 0;
  p {
    text-align: justify;
    color: white;
    padding: 25px;
  }
  a {
    color: white;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  h3 {
    padding: 0 25px;
  }
`;

const GalleryWrapper = styled.div`
  padding: ${({ isMobile }) => (isMobile ? '' : '25px')};
`;
