import { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import ScrollToTop from './hooks/useScrollToTop';
import Sidebar from './Components/Ui/Sidebar';
import Harkany from './Pages/Harkany';
import HarkanyHungarian from './Pages/HarkanyHungarian';
import Contact from './Pages/Contact';
import Trips from './Pages/Trips';
import House from './Pages/House';
import Home from './Pages/Home';
import Footer from './Components/Footer';
import Impressum from './Components/Impressum';
import ImpressumHungarian from './Components/ImpressumHungarian';
import Datenschutz from './Components/Datenschutz';
import DatenschutzHungarian from './Components/DatenschutzHungarian';
import CountryButton from './Components/CountryButton';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import background from './assets/background.jpg';

function App() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [isGerman, setIsGerman] = useState(false);

  let headline;
  let noHeadline = false;

  const isDataInfo =
    location.pathname === '/datenschutz' || location.pathname === '/impressum';

    if (location.pathname === '/harkany') {
      headline = isGerman ? 'Harkány' : 'Harkány'; 
    } else if (location.pathname === '/blaueshaus') {
      headline = isGerman ? 'Das Blaue Haus' : 'A Kék Ház'; 
    } else if (location.pathname === '/ausfluege') {
      headline = isGerman ? 'Ausflüge' : 'Kirándulások';
    } else if (location.pathname === '/contact') {
      headline = isGerman ? 'Kontakt' : 'Kapcsolat'; 
    } else if (location.pathname === '/impressum') {
      headline = isGerman ? 'Impressum' : 'Impresszum'; 
    } else if (location.pathname === '/datenschutz') {
      headline = isGerman ? 'Datenschutz' : 'Adatvédelem'; 
    } else if (location.pathname === '/') {
      noHeadline = true;
    }
  return (
    <ScrollToTop>
      <Container isDataInfo={isDataInfo}>
      {isMobile &&   (
        <MobileHeadlineWrapper> <CountryButton isGerman={isGerman} onClick={() => setIsGerman(!isGerman)} /></MobileHeadlineWrapper>
 
)}
        <Sidebar setOpen={setOpen} open={open} isGerman={isGerman} setIsGerman={setIsGerman}/>
        <Wrapper noHeadline={noHeadline} isMobile={isMobile}>
        <h2>{headline}</h2>
          <Switch>
            <Route exact path="/">
              <Home isGerman={isGerman}/>
            </Route>
            <Route path="/blaueshaus">
              <House isGerman={isGerman} />
            </Route>
            <Route path="/harkany">
              {isGerman ? <Harkany  /> : <HarkanyHungarian  /> }
              
            </Route>
            <Route path="/ausfluege">
              <Trips isGerman={isGerman} />
            </Route>
            <Route path="/contact">
              <Contact isGerman={isGerman}/>
            </Route>
            <Route path="/impressum">
            {isGerman ?  <Impressum /> :  <ImpressumHungarian/>}
        
            </Route>
            <Route path="/datenschutz">
              {isGerman ? <Datenschutz  /> : <DatenschutzHungarian/>}
            </Route>
          </Switch>
        </Wrapper>
        <Footer open={open} isGerman={isGerman} />
      </Container>
    </ScrollToTop>
  );
}

export default App;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: url(${({ isDataInfo }) => !isDataInfo && background});
  background-size: 3648px 5472px;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
`;

const HeadlineWrapper = styled.div`
display: flex;
justify-content: space-between; 
align-items: center;
`;

const MobileHeadlineWrapper = styled.div`
padding: 1em 1em;
right: 0;
position: fixed;
z-index: 6;
`;


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  padding: 0em 0.3em;
  min-height: 100vh;
  /* background: var(--clr-bg); */
  h2 {
    display: ${({ noHeadline }) => (noHeadline ? 'none' : '')};
    color: rgb(5, 82, 142);
    padding: 1em 0em;
    margin-top: ${({ isMobile }) => (isMobile ? '3rem' : '')};
  }
`;
