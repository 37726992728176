import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobile, isTablet } from 'react-device-detect';

const VideoWrapper = styled.div`
  iframe {
    width: ${isMobile ? '320px' : isTablet ? '640px' : '800px'}; 
    height: ${isMobile ? '180px' : isTablet ? '360px' : '450px'}; 
    border: 0;
  }
`;

const ConsentContainer = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  height: 100%; 

  p {
    text-align: center; 
    margin-bottom: 16px;
    color: white; 
  }

  button {
    background-color: transparent; 
    color: white; 
    border: 1px solid white; 
    border-radius: 15px;
    padding: 8px 16px;
    font-size: 16px; 
    cursor: pointer;
    transition: background-color 0.3s ease; 

    &:hover {
      background-color: white;
      color: #000; 
    }
  }
`;




function YouTubeVideo() {
  const videoSrc = `https://www.youtube-nocookie.com/embed/aLTisWP3RR0`;
  return (
    <VideoWrapper>
      <iframe

        src={videoSrc}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </VideoWrapper>
  );
}

function VideoWithConsent({isGerman}) {
  const [consentGiven, setConsentGiven] = useState(false);

  const handleConsent = () => {
    setConsentGiven(true);
  };

  const germanText = 'Externe Inhalte von Youtube erlauben?'
  const hungarianText = 'Engedélyezze a Youtube külső tartalmait?'

  return (
    <div>
      {consentGiven ? (
        <YouTubeVideo />
      ) : (
        <ConsentContainer>
            {isGerman ? <p>{germanText}</p> : <p>{hungarianText}</p>}
         
          <button onClick={handleConsent}>{isGerman ? 'Erlauben' : 'Engedélyezem' }</button>
        </ConsentContainer>
      )}
    </div>
  );
}

export default VideoWithConsent;
export { YouTubeVideo };
