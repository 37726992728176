import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export default function Impressum() {
  const target = isMobile ? '' : '_blank';
  const rel = isMobile ? '' : 'noopener noreferrer';

  return (
    <Wrapper isMobile={isMobile}>
      <InnerWrapper>
        <SublineWrapper isMobile={isMobile}>
          <h3>Angaben gemäß § 5 TMG:</h3>

          <p>Dr. Judit Molnar-Toriz</p>
          <h3>Postanschrift:</h3>
          <p>
            Eichgasse 2<br /> 72379 Hechingen
          </p>
          <h3>Kontakt:</h3>
          <p>
            Telefon: +49747113181 <br />
            E-Mail: judit.molnar@web.de
          </p>

          <h3>Hinweise zur Website</h3>

          <p>Urheberrechtliche Hinweise:</p>

          <ul>
            <li>
              Bilder mit Lizenz von&nbsp;
              <a
                href="https://www.shutterstock.com/de/home"
                rel={rel}
                target={target}
              >
                Shutterstock
              </a>
            </li>

            <li>
              Das Impressum wurde mit dem{' '}
              <a
                href="https://www.activemind.de/datenschutz/impressums-generator/"
                rel={rel}
                target={target}
              >
                Impressums-Generator der activeMind AG
              </a>{' '}
              erstellt.
            </li>
          </ul>
        </SublineWrapper>
      </InnerWrapper>
    </Wrapper>
  );
}

const SublineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: ${({ isMobile }) => (isMobile ? '50px 0 50px 0' : '50px 0')};

  h3 {
    margin: 1rem 1rem 0 1rem;
    line-height: 1.1;
  }

  ul {
    color: white;

    li {
      margin-bottom: 1rem;
    }
  }

  p {
    text-align: justify;
    color: white;
    margin: 1rem;
  }

  a {
    color: white;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  box-shadow: var(--bs2);
  border-radius: 10px;
  overflow-x: hidden;
  padding: 50px 0 0 0;
  p {
    text-align: justify;
    color: white;
    padding: 25px 25px 0 25px;
  }
  a {
    color: white;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  background: ${({ isMobile }) =>
    isMobile ? 'rgb(71, 127, 172)' : 'var(--clr-bg)'};
  box-shadow: var(--bs2);
  border-radius: 10px;
  /* padding: 25px; */
  h3 {
    padding: 0 25px;
  }
`;
