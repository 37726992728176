import React from 'react';
import { HU, DE } from 'country-flag-icons/react/3x2'

export default function CountryButton({isGerman, onClick}){
    return (
        <div 
            role="button" 
             onClick={onClick}
            style={{ cursor: 'pointer', display: 'inline-block', width: '30px', height: '30px' /* add other styles as needed */ }}
        >
            {!isGerman ? <DE title="Germany" style={{ width: '100%', height: '100%' }}/> : <HU title="Hungary" style={{ width: '100%', height: '100%' }}/>}
        </div>
    );
}
