import { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { ArrowRightCircle } from '@styled-icons/bootstrap/ArrowRightCircle';
import { ArrowLeftCircle } from '@styled-icons/bootstrap/ArrowLeftCircle';
import { ZoomIn } from '@styled-icons/bootstrap/ZoomIn';
import Modal from '../Components/Modal';

import spinner from '../assets/spinner.gif';

export default function Gallery({ images }) {
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [modalCount, setModalCount] = useState(0);
  const imagesToUse = images.map((image) => image.url);

  const mobileNotTablet = isMobile && !isTablet;

  const handleNextPage = () => {
    count + 1 < imagesToUse.length && setCount((prevcount) => prevcount + 1);
  };

  const handlePrevPage = () => {
    count !== 0 && setCount((prevcount) => prevcount - 1);
  };

  const isFirstPic = count === 0;
  const isLastPic = count === imagesToUse.length - 1;

  const openHandler = () => {
    setModalCount(count);
    setOpen(true);
  };

  const [imgsLoaded, setImgsLoaded] = useState(false);

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.url;
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.url);
          }, 2000);

        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(images.map((image) => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch((err) => console.log('Failed to load images', err));
  }, []);

  return (
    <Wrapper>
      <ImageWrapper isMobile={isMobile}>
        <OuterPics
          imgsLoaded={imgsLoaded}
          isFirstPic={isFirstPic}
          isMobile={isMobile}
          background={
            !imgsLoaded
              ? spinner
              : isFirstPic
              ? imagesToUse[count]
              : imagesToUse[count - 1]
          }
        ></OuterPics>
        <MiddleWrapper
          mobileNotTablet={mobileNotTablet}
          isMobile={isMobile}
          background={imgsLoaded ? imagesToUse[count] : spinner}
          imgsLoaded={imgsLoaded}
        >
          <CountWrapper isMobile={isMobile}>
            <PrevIcon onClick={handlePrevPage} isFirstPic={isFirstPic} />
            <ZoomIcon onClick={openHandler} />
            <NextIcon onClick={handleNextPage} isLastPic={isLastPic} />
          </CountWrapper>
        </MiddleWrapper>
        <OuterPics
          imgsLoaded={imgsLoaded}
          isMobile={isMobile}
          isLastPic={isLastPic}
          background={
            !imgsLoaded
              ? spinner
              : isLastPic
              ? imagesToUse[count]
              : imagesToUse[count + 1]
          }
        ></OuterPics>
      </ImageWrapper>
      <Modal
        modalCount={modalCount}
        setModalCount={setModalCount}
        setOpen={setOpen}
        images={imagesToUse}
        open={open}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 350px;
  width: 100%;
  display: flex;
  padding: 2rem 0;
  /* background: var(--clr-grdbg); */
  margin-bottom: 1rem;
  justify-content: center;
  overflow-x: hidden;
`;

const MiddleWrapper = styled.div(
  (props) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: ${({ mobileNotTablet }) => (mobileNotTablet ? '85%' : '50%')};
    flex-shrink: 0;
    cursor: zoom-in;
    box-shadow: var(--bs);
    filter: blur(0px);
    -webkit-filter: blur(0px);
    margin: ${({ isMobile }) => (isMobile ? '0 12.5px' : '0 25px')};
    background: ${(props) =>
      `url("${props.background}") no-repeat center center`};
    background-size: ${({ imgsLoaded }) =>
      !imgsLoaded ? '150px 150px' : 'cover'};
    background-position: center center;
    border-radius: 10px;
  `
);

const OuterPics = styled.div(
  (props) => css`
    display: flex;
    visibility: ${({ isLastPic }) => (isLastPic ? 'hidden' : 'visible')};
    opacity: ${({ isFirstPic }) => (isFirstPic ? '0' : '1')};
    flex-shrink: 0;
    width: ${({ isMobile }) => (isMobile ? '225px' : '500px')};
    height: 80%;
    box-shadow: var(--bs);
    filter: blur(1px);
    -webkit-filter: blur(1px);
    background: ${(props) =>
      `url("${props.background}") no-repeat center center`};
    background-size: ${({ imgsLoaded }) => (!imgsLoaded ? 'contain' : 'cover')};
    border-radius: 10px;
  `
);

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: var(--clr-tools);
  padding: 0.5rem 0;
`;

const PrevIcon = styled(ArrowLeftCircle)`
  color: white;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  visibility: ${({ isFirstPic }) => (isFirstPic ? 'hidden' : 'visible')};
`;

const ZoomIcon = styled(ZoomIn)`
  color: white;
  height: 2rem;
  width: 2rem;

  cursor: pointer;
  margin: 0 2rem;
`;

const NextIcon = styled(ArrowRightCircle)`
  color: white;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  visibility: ${({ isLastPic }) => (isLastPic ? 'hidden' : 'visible')};
`;
