import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { GlassWhiskey } from '@styled-icons/fa-solid/GlassWhiskey';
import { Food } from '@styled-icons/fluentui-system-regular/Food';
import { Washer } from '@styled-icons/boxicons-solid/Washer';
import { People } from '@styled-icons/bootstrap/People';
import { Shower } from '@styled-icons/fa-solid/Shower';
import { Tv } from '@styled-icons/bootstrap/Tv';
import { ArrowDropDownCircle } from '@styled-icons/material-outlined/ArrowDropDownCircle';
import { Wifi } from '@styled-icons/material/Wifi';

import { useState } from 'react';

export default function CompleteHouse({ isGerman }) {
  const [showFeatures, setShowFeatures] = useState(false);

  return (
    <DropdownWrapper isMobile={isMobile}>
      <DescriptionWrapper isMobile={isMobile}>
        <Subline>{isGerman ? 'Ausstattung' : 'Felszereltség'}</Subline>
        <DropdownDescription
          onClick={() => setShowFeatures(!showFeatures)}
          showFeatures={showFeatures}
        />
      </DescriptionWrapper>
      <Flexbox showFeatures={showFeatures}>
        <Grid>
          <div>
            <RoomIcon />
            <p>{isGerman ? '5 Schlafplätze (4 fix, 1 ausziehbar' : '4 fix férőhely (+1 pótágy)'}</p>
          </div>
          <div>
            <TvIcon />
            <p>TV</p>
          </div>
          <div>
            <InternetIcon />
            <p>Wifi</p>
          </div>
          <div>
            <ShowerIcon />
            <p>{isGerman ? '1 Badezimmer' : 'saját fürdőszoba'}</p>
          </div>
          <div>
            <Kitchen />
            <p>{isGerman ? 'Küche' : 'saját konyha'}</p>
          </div>
          <div>
            <WasherIcon />
            <p>{isGerman ? 'Spülmaschine' : 'mosogatógép'}</p>
          </div>
          <div>
            <LaundryIcon />
            <p>{isGerman ? 'Waschmaschine' : 'mosógép'}</p>
          </div>
        </Grid>
      </Flexbox>
    </DropdownWrapper>
  );
}


const InternetIcon = styled(Wifi)`
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const TvIcon = styled(Tv)`
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const Subline = styled.p`
  font-weight: bold;
  letter-spacing: 1px;
`;

const DropdownDescription = styled(ArrowDropDownCircle)`
  color: white;
  width: 25px;
  height: 25px;
  transition: transform 550ms;
  cursor: pointer;
  transform: ${({ showFeatures }) => (showFeatures ? 'rotate(-180deg)' : '')};
`;

const DropdownWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
  justify-content: flex-start;
  flex-wrap: ${({ isMobile }) => (isMobile ? 'wrap' : 'wrap')};
  max-width: 1000px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 13rem;

  p {
    line-height: 1.75;
    text-align: left;
    padding: ${({ isMobile }) => (isMobile ? '0 0 0 25px' : '0 0 0 50px')};
    margin: 0;
    color: white;
  }
`;

const Flexbox = styled.div`
  display: ${({ showFeatures }) => (showFeatures ? 'flex' : 'none')};
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1000px;
  padding: 16px;
`;

const Grid = styled.div`
  padding: 3rem 0;
  display: grid;
  margin: 0 auto;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 830px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }

  div {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    align-items: center;
    border: solid 1px white;
    padding: 5px;
    border-radius: 10px;
    min-width: 10rem;
    p {
      color: white;
      margin-left: 1rem;
      margin: 0;
    }
  }
`;

const LaundryIcon = styled(Washer)`
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const WasherIcon = styled(GlassWhiskey)`
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const RoomIcon = styled(People)`
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const ShowerIcon = styled(Shower)`
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0;
  margin-right: 1rem;
  flex-shrink: 0;
`;


const Kitchen = styled(Food)`
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;
