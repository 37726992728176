import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Footer({isGerman}) {

  const datenSchutzName = isGerman ? 'Datenschutz' : 'Adatvédelem';
  const impressumName = isGerman ?'Impressum' : 'Impresszum';
  return (
    <StyledFooter>
      <Wrapper>
        <StyledLink
          to={{
            pathname: '/datenschutz',
          }}
        >
          {datenSchutzName}
        </StyledLink>
        <StyledLink
          to={{
            pathname: '/impressum',
          }}
        >
           {impressumName}
        </StyledLink>
      </Wrapper>
    </StyledFooter>
  );
}

const StyledLink = styled(Link)`
  text-decoration: none;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.7rem 0;
  margin: 0 auto;
  max-width: 900px;

  a {
    color: white;
    text-decoration: none;
  }

  img {
    max-width: 50px;
    color: white;
  }
`;

const StyledFooter = styled.footer`
  color: white;
  width: 100%;
  background: var(--clr-bg);
  margin-top: 2rem;
  box-shadow: var(--bs2);
`;
