import houseOuter1 from '../assets/house/outside/1.jpg';
import houseOuter2 from '../assets/house/outside/2.jpg';
import houseOuter3 from '../assets/house/outside/3.jpg';
import houseOuter4 from '../assets/house/outside/4.jpg';
import houseOuter5 from '../assets/house/outside/5.jpg';
import houseOuter6 from '../assets/house/outside/6.jpg';
import houseOuter7 from '../assets/house/outside/7.jpg';
import houseOuter8 from '../assets/house/outside/8.jpg';
import houseOuter9 from '../assets/house/outside/9.jpg';
import houseOuter10 from '../assets/house/outside/10.jpg';
import houseOuter11 from '../assets/house/outside/11.jpg';
import houseOuter12 from '../assets/house/outside/12.jpg';

import innerBig1 from '../assets/house/inside/big/1.jpg';
import innerBig2 from '../assets/house/inside/big/2.jpg';
import innerBig3 from '../assets/house/inside/big/3.jpg';
import innerBig4 from '../assets/house/inside/big/4.jpg';
import innerBig5 from '../assets/house/inside/big/5.jpg';
import innerBig6 from '../assets/house/inside/big/6.jpg';
import innerBig7 from '../assets/house/inside/big/7.jpg';
import innerBig8 from '../assets/house/inside/big/8.jpg';
import innerBig9 from '../assets/house/inside/big/9.jpg';
import innerBig10 from '../assets/house/inside/big/10.jpg';
import innerBig11 from '../assets/house/inside/big/11.jpg';
import innerBig12 from '../assets/house/inside/big/12.jpg';
import innerBig13 from '../assets/house/inside/big/13.jpg';
import innerBig14 from '../assets/house/inside/big/14.jpg';


import innerSmall1 from '../assets/house/inside/small/1.jpg';
import innerSmall2 from '../assets/house/inside/small/2.jpg';
import innerSmall3 from '../assets/house/inside/small/3.jpg';
import innerSmall4 from '../assets/house/inside/small/4.jpg';
import innerSmall5 from '../assets/house/inside/small/5.jpg';

import villany1 from '../assets/villany/villany1.jpg';
import villany2 from '../assets/villany/villany2.jpg';
import villany3 from '../assets/villany/villany3.jpg';

import siklos1 from '../assets/siklos/siklos1.jpg';
import siklos2 from '../assets/siklos/siklos2.jpg';
import siklos3 from '../assets/siklos/siklos3.jpg';

import pecs1 from '../assets/pecs/pecs1.jpg';
import pecs2 from '../assets/pecs/pecs2.jpg';
import pecs3 from '../assets/pecs/pecs3.jpg';

import harkany1 from '../assets/harkany/harkany1.jpg';

import fishing1 from '../assets/fishing/1.jpeg';
import fishing2 from '../assets/fishing/2.jpeg';
import fishing3 from '../assets/fishing/3.jpeg';
import fishing4 from '../assets/fishing/4.jpeg';

export const house = [
  {
    outer: [
      {
        id: 1,
        url: houseOuter1,
        text: '',
      },
      {
        id: 2,
        url: houseOuter6,
        text: '',
      },
      {
        id: 3,
        url: houseOuter3,
        text: '',
      },
      {
        id: 4,
        url: houseOuter4,
        text: '',
      },

      {
        id: 5,
        url: houseOuter5,
        text: '',
      },
      {
        id: 6,
        url: houseOuter2,
        text: '',
      },

      {
       id: 7,
        url: houseOuter7,
         text: '',
      },
     {
       id: 8,
         url: houseOuter8,
        text: '',
       },
      {
        id: 9,
        url: houseOuter9,
        text: '',
      },

      {
        id: 10,
        url: houseOuter10,
        text: '',
      },
      {
        id: 11,
        url: houseOuter11,
        text: '',
      },

      {
        id: 12,
        url: houseOuter12,
        text: '',
      },
    ],
    innerbig: [
      {
        id: 8,
        url: innerBig5,
        text: '',
      },
      {
        id: 9,
        url: innerBig2,
        text: '',
      },

      {
        id: 11,
        url: innerBig4,
        text: '',
      },

      {
        id: 12,
        url: innerBig1,
        text: '',
      },
      {
        id: 13,
        url: innerBig6,
        text: '',
      },

      {
        id: 14,
        url: innerBig7,
        text: '',
      },
      {
        id: 15,
        url: innerBig8,
        text: '',
      },
      {
        id: 16,
        url: innerBig9,
        text: '',
      },
      {
        id: 17,
        url: innerBig10,
        text: '',
      },
      {
        id: 2312,
        url: innerBig11,
        text: '',
      },
      {
        id: 23,
        url: innerBig12,
        text: '',
      },
      {
        id: 23412,
        url: innerBig13,
        text: '',
      },
    ],
    innersmall: [
      {
        id: 213322,
        url: innerSmall2,
        text: '',
      },
      {
        id: 2133213,
        url: innerSmall1,
        text: '',
      },
      {
        id: 213323,
        url: innerSmall3,
        text: '',
      },
      {
        id: 21334,
        url: innerSmall4,
        text: '',
      },
      {
        id: 2135,
        url: innerSmall5,
        text: '',
      },
    ],
  },
];

export const villany = [
  {
    id: 1,
    url: villany1,
    text: '',
  },
  {
    id: 2,
    url: villany2,
    text: '',
  },
  {
    id: 3,
    url: villany3,
    text: '',
  },
];

export const pecs = [
  {
    id: 1,
    url: pecs1,
    text: '',
  },
  {
    id: 2,
    url: pecs2,
    text: '',
  },
  {
    id: 3,
    url: pecs3,
    text: '',
  },
];

export const siklos = [
  {
    id: 1,
    url: siklos1,
    text: '',
  },
  {
    id: 2,
    url: siklos2,
    text: '',
  },
  {
    id: 3,
    url: siklos3,
    text: '',
  },
];

export const harkany = [
  {
    id: 1,
    url: harkany1,
    text: '',
  },
  {
    id: 2,
    url: harkany1,
    text: '',
  },
  {
    id: 3,
    url: harkany1,
    text: '',
  },
];

export const fishing = [
  {
    id: 1,
    url: fishing1,
    text: '',
  },
  {
    id: 2,
    url: fishing2,
    text: '',
  },
  {
    id: 3,
    url: fishing3,
    text: '',
  },
  {
    id: 4,
    url: fishing4,
    text: '',
  },
];
