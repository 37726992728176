import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export default function Burger({ open, setOpen, show }) {
  if (!isMobile) return null;

  return (
    <StyledBurger onClick={() => setOpen(!open)} open={open} show={show}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
}

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  height: 2rem;
  left: 1rem;
  padding: 0;
  position: fixed;
  will-change: transform;
  top: 1rem;
  width: 2rem;
  z-index: 4;

  &:focus {
    outline: none;
  }
  div {
    border-radius: 10px;
    height: 0.25rem;
    position: relative;
    transform-origin: 1px;
    transition: all 0.3s linear;
    width: 2rem;
    :first-child {
      background: ${({ open }) =>
        open || window.scrollY <= 120 ? 'rgb(5, 82, 142)' : 'white'};
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      background: ${({ open }) =>
        open || window.scrollY <= 120 ? 'rgb(5, 82, 142)' : 'white'};
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      background: ${({ open }) =>
        open || window.scrollY <= 120 ? 'rgb(5, 82, 142)' : 'white'};
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
