import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { MailWithCircle } from '@styled-icons/entypo-social/MailWithCircle';

const address = "mailto:das.blaue.haus.hu@gmail.com";

export default function ContactPage({ isGerman }) {
  return (
    <Wrapper>
      <InnerWrapper>
        <GalleryWrapper isMobile={isMobile}>
          <h3>{isGerman ? 'Buchung & Informationen' : 'Szállásfoglalás'}</h3>
          <p>
            {isGerman ?
              'Bei Fragen rund um das Blaue Haus bzw. zu einer Buchung stehen wir Ihnen jederzeit gerne zur Verfügung. Nach erster Kontaktaufnahme per Mail lassen wir Ihnen umgehend unsere Telefonnummer zukommen, unter der Sie uns – auch während Ihres Aufenthalts im Blauen Haus – jederzeit erreichen können. Gerne beraten wir Sie auch bezüglich weiterer Sehenswürdigkeiten und Aktivitäten, die Ihnen während Ihres Aufenthalts zur Verfügung stehen.' :
              'Ha megtetszett Önnek Vendégházunk és a környék látnivalóinak felfedezése mellett nálunk szeretne pihenni, vegye fel velünk közvetlenül a kapcsolatot az alábbi elérhetőségeken! '
            }
      
          </p>
        </GalleryWrapper>
      
        <MailWrapper isMobile={isMobile}>
          <a href={address}>
            {' '}
            <MailIcon isMobile={isMobile} />
          </a>
          <a href={address}>
            <p>das.blaue.haus.hu@gmail.com</p>
          </a>
          <p>Tel.: 0036-20 395 6462</p>
        </MailWrapper>
      </InnerWrapper>
    </Wrapper>
  );
}

const MailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: ${({ isMobile }) => (isMobile ? '25px 0 40px 0' : '0 0 45px 0')};
  p {
    margin: 0;
    padding: 0;
  }
`;

const MailIcon = styled(MailWithCircle)`
  color: white;
  width: ${({ isMobile }) => (isMobile ? '60px' : '60px')};
  cursor: pointer;
  transition: transform 450ms;
  &:hover {
    transform: scale(1.1);
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  box-shadow: var(--bs2);
  border-radius: 10px;
  overflow-x: hidden;
  padding: 50px 0 0 0;
  p {
    text-align: justify;
    color: white;
    padding: 25px 25px 0 25px;
  }
  a {
    color: white;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  background: var(--clr-bg);
  box-shadow: var(--bs2);
  border-radius: 10px;

  h3 {
    padding: 0 25px;
  }
`;

const GalleryWrapper = styled.div`
  padding: ${({ isMobile }) => (isMobile ? '' : '25px')};
  h3 {
    text-align: center;
  }
`;
