import ReactDom from 'react-dom';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { ArrowLeftCircle } from '@styled-icons/bootstrap/ArrowLeftCircle';
import { ArrowRightCircle } from '@styled-icons/bootstrap/ArrowRightCircle';
import { Close } from '@styled-icons/evaicons-solid/Close';

export default function Modal({
  open,
  images,
  setOpen,
  modalCount,
  setModalCount,
}) {
  if (!open) return null;

  const isLastPicture = modalCount + 1 === images.length;
  const isFirstPicture = modalCount + 1 === 1;

  const handleNextPage = () => {
    modalCount + 1 < images.length &&
      setModalCount((prevcount) => prevcount + 1);
  };

  const handlePrevPage = () => {
    modalCount !== 0 && setModalCount((prevcount) => prevcount - 1);
  };

  const closeHandler = () => {
    setOpen(false);
    setModalCount(0);
  };

  return ReactDom.createPortal(
    <>
      <BackgroundStyler onClick={closeHandler} />
      <ModalStyler>
        <CloseIcon onClick={closeHandler} />
        <ImageWrapper
          isMobile={isMobile}
          background={images[modalCount]}
        ></ImageWrapper>
        <CountWrapper>
          <PrevIcon onClick={handlePrevPage} isFirstPicture={isFirstPicture} />
          <p>
            {modalCount + 1} / {images.length}{' '}
          </p>

          <NextIcon onClick={handleNextPage} isLastPicture={isLastPicture} />
        </CountWrapper>
      </ModalStyler>
    </>,
    document.getElementById('portal')
  );
}

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: var(--clr-tools);

  padding: 0.5rem 0;
  p {
    margin: 0;
    color: white;
  }
`;

const BackgroundStyler = styled.div`
  background-color: var(--clr-bg-modal);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

const ModalStyler = styled.div`
  background: var(--clr-bg);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  left: 50%;
  width: 90%;
  height: 50%;
  max-width: 1000px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
  }
`;

const ImageWrapper = styled.div(
  (props) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    box-shadow: var(--bs);
    background: ${(props) => `url("${props.background}") `};
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  `
);

const CloseIcon = styled(Close)`
  color: white;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const PrevIcon = styled(ArrowLeftCircle)`
  color: white;
  height: 2rem;
  width: 2rem;
  margin-right: 1.3rem;
  cursor: pointer;
  visibility: ${({ isFirstPicture }) =>
    isFirstPicture ? 'hidden' : 'visible'};
`;

const NextIcon = styled(ArrowRightCircle)`
  color: white;
  height: 2rem;
  width: 2rem;
  margin-left: 1.3rem;
  cursor: pointer;
  visibility: ${({ isLastPicture }) => (isLastPicture ? 'hidden' : 'visible')};
`;
