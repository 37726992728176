import Burger from './Burger';
import Menu from './Menu';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { useEffect, useState } from 'react';

export default function Sidebar({ open, setOpen, isGerman, setIsGerman }) {
  const [show, handleShow] = useState(false);

  useEffect(() => {
    const scrollDown = () =>
      window.scrollY > 120 ? handleShow(true) : handleShow(false);
    window.addEventListener('scroll', scrollDown, { passive: true });
    return () => {
      window.removeEventListener('scroll', scrollDown);
    };
  }, [handleShow]);

  return (
    <>
      <BurgerWrapper isMobile={isMobile} show={show} open={open}>
        <Burger open={open} setOpen={setOpen} show={show} />
        {/* <img src={stencil} alt="" /> */}
      </BurgerWrapper>
      <Menu open={open} setOpen={setOpen} isGerman={isGerman} setIsGerman={setIsGerman} />
    </>
  );
}

const BurgerWrapper = styled.div`
  /* display: ${({ isMobile }) => (isMobile ? 'flex' : 'none')};

  justify-content: flex-start;
  width: 100%;
  height: 3.45rem;
  background: none;
  position: fixed;

  z-index: 4;

  img {
    display: ${({ open }) => (open ? 'static' : 'none')};
    margin: 5px 0 5px 4rem;
    z-index: 1;
  } */
`;
