import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export default function ImpressumHungarian() {
  const target = isMobile ? '' : '_blank';
  const rel = isMobile ? '' : 'noopener noreferrer';

  return (
    <Wrapper isMobile={isMobile}>
      <InnerWrapper>
        <SublineWrapper isMobile={isMobile}>
          <h3>Tájékoztatás a TMG (Német Telemedia Törvény) 5. §-nak megfelelően</h3>
          <p>
            Tulajdonos: <br/><br/>Dr. Judit Molnar-Toriz<br />
          Eichgasse 2<br /> 72379 Hechingen, Németerország<br/> 
          Telefon: +49747113181 <br /> Mail: <a href="mailto:judit.molnar@web.de">judit.molnar@web.de</a>
          </p>

     
          <p>
          Megbízott, teljes jogú ügyvivő:<br/><br/>
          Dr. Schneider Csaba<br />
          Adószám: 57560634-2-51<br />
          NTAK regisztrációs szám: MA21005985<br />
          KSH azonosító: 57560634-9001-233-07<br />
          <a href="mailto:dr.schneider.csaba@gmail.com">dr.schneider.csaba@gmail.com</a>
          </p>

          <h3>Megjegyzések a weboldalhoz
</h3>

          <p>Szerzői jogi információk:</p>

          <ul>
            <li>
            A fotókat a&nbsp;
              <a
                href="https://www.shutterstock.com/de/home"
                rel={rel}
                target={target}
              >
                Shutterstock
              </a>
              &nbsp;engedélyével használjuk.
            </li>

            <li>
            Az impresszum az{' '}
              <a
                href="https://www.activemind.de/datenschutz/impressums-generator/"
                rel={rel}
                target={target}
              >
                activeMind AG impresszumgenerátoráva
              </a>{' '}
              készült.
            </li>
          </ul>
        </SublineWrapper>
      </InnerWrapper>
    </Wrapper>
  );
}

const SublineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: ${({ isMobile }) => (isMobile ? '50px 0 50px 0' : '50px 0')};

  h3 {
    margin: 1rem 1rem 0 1rem;
    line-height: 1.1;
  }

  ul {
    color: white;

    li {
      margin-bottom: 1rem;
    }
  }

  p {
    text-align: justify;
    color: white;
    margin: 1rem;
  }

  a {
    color: white;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  box-shadow: var(--bs2);
  border-radius: 10px;
  overflow-x: hidden;
  padding: 50px 0 0 0;
  p {
    text-align: justify;
    color: white;
    padding: 25px 25px 0 25px;
  }
  a {
    color: white;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  background: ${({ isMobile }) =>
    isMobile ? 'rgb(71, 127, 172)' : 'var(--clr-bg)'};
  box-shadow: var(--bs2);
  border-radius: 10px;
  /* padding: 25px; */
  h3 {
    padding: 0 25px;
  }
`;
