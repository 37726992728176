import styled from 'styled-components';
import logo from '../assets/house/outside/1.jpg';
import { isMobile, isTablet } from 'react-device-detect';

export default function Home({ isGerman }) {
  return (
    <Wrapper>
      <StyledSection isMobile={isMobile} isTablet={isTablet}>
        <SectionTitle>
          {isGerman ? 
            <>Willkommen im <strong>Blauen Haus</strong></> : 
            <>Üdvözöljük a <strong>Das Blaue Haus</strong> Vendégház<br/> weboldalán</> 
          }
        </SectionTitle>
        <SectionSubtitle>{isGerman ? 'Harkány, Ungarn' : 'Harkány, Magyarország'}</SectionSubtitle> 
        <Image src={logo} alt={isGerman ? "ein Portrait des Blauen Hauses" : "a Kék Ház portréja"} /> 
      </StyledSection>
    </Wrapper>
  );
}

const Image = styled.img``;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  padding: 1em;
`;

const StyledSection = styled.section`
  background: none;
  margin-top: ${({ isMobile }) => (isMobile ? '3.5rem' : '5rem')};
  @media (min-width: ${({ isTablet }) => (isTablet ? '1100px' : '600px')}) {
    display: grid;
    width: min-content;
    grid-column-gap: 1em;
    grid-template-areas:
      'img title'
      'img subtitle';
    grid-template-columns: min-content max-content;
    p {
      color: white;
      align-self: start;
      grid-column: -1/1;
      grid-row: 2;
      text-align: right;
      position: relative;
      left: -1.5em;
      width: calc(100% + 1.5em);
    }
  }
  img {
    grid-area: img;
    min-width: ${({ isMobile }) => (isMobile ? '250px' : '500px')};
    position: relative;
    z-index: 2;
    box-shadow: var(--bs);
    border-radius: 10px;
  }
`;

const SectionTitle = styled.h1`
  margin-bottom: 0.25em;
  font-weight: var(--fw-reg);
  color: rgb(5, 82, 142);
  strong {
    display: block;
  }
`;
const SectionSubtitle = styled.p`
  margin: 0;
  padding: 0.25em 1em;
  font-size: var(--fs-h3);
  background-color: var(--clr-bg);
  border-radius: 10px;
  margin-bottom: 1em;
  display: inline-block;
  color: white;
`;
