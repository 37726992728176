import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Gallery from '../Components/Gallery';
import VideoWithConsent from '../Components/YouTube';
import { house } from '../data/images';


import CompleteHouse from '../Components/CompleteHouse';
import FlatOne from '../Components/FlatOne';
import FlatTwo from '../Components/FlatTwo';
import ApartmentOneHungarian, {ApartmentTwoHungarian} from '../Components/PricingTable';

export default function House({ isGerman }) {
  const outsideImages = house[0].outer;
  const innerBigImages = house[0].innerbig;
  const innerSmallImages = house[0].innersmall;

  return (
    <Wrapper>
      <Container>
        <SublineWrapper isMobile={isMobile}>
          <h3>{isGerman ? 'Überblick' : 'Áttekintés'}</h3>
        </SublineWrapper>
        <Gallery images={outsideImages} />
        <History isMobile={isMobile}>
          {isGerman ? (
            <p> Das Blaue Haus ist ein 1905 errichtetes Lehmhaus, das unter Denkmalschutz steht. Im Jahr 2021 wurde es umfassend renoviert und die Inneneinrichtung modernisiert. Hierbei wurde besonders darauf geachtet, dass der Charme des Landhauses unverändert bestehen bleibt. Es bietet seinen Gästen zwei Wohnungen mit jeweils 4 bzw. 2 Schlafplätzen und ein separates kleines Häuschen, das über 2 Schlafplätze verfügt. Das überregional bekannte und beliebte Thermalbad der Stadt Harkány liegt nur 5 km entfernt, sodass sich das Blaue Haus ideal für einen ausgedehnten Wellnessurlaub eignet. Die Region Baranya im Südosten Ungarns bietet zudem eine Vielzahl von weiteren Sehenswürdigkeiten und Aktivitäten. Sei es ein Einkaufsbummel und das Entdecken der Kulturhauptstadt Europas 2010 Pécs, das Genießen edler Tropfen bei Winzern des berühmten Weinbaugebiets Villány oder die feurige Küche einer ungarischen Csárda: das Blaue Haus heißt Sie zu einem abwechslungsreichen und entspannenden Aufenthalt willkommen!&nbsp;&nbsp;</p>
          ) : (
            <p>
             A Vendégház a Baranya-megyei Kovácshidán egy műemlékvédelem alatt álló, 1905-ben épült vályogház. 2021-ben teljes felújításon esett át, amely során belső tereit a mai kornak megfelelően alakítottuk ki és rendeztük be. Különös gondot fordítottunk arra, hogy a tájház varázsa változatlan maradjon. Vendégeink számára két külön bejáratú és különböző nagyságú apartman áll rendelkezésre: a nagyobb, „Wohnung 1” (W1) elnevezésű, amelyet tágas lakrészeivel, amerikai konyhás étkezőjével és nappalijával főként családoknak ajánljuk, 6 fő elhelyezésére alkalmas. A kisebb „Wohnung 2” (W2) egy szintén amerikai konyhás, nappalis, kisebb hálószobával rendelkező, kompakt, 4 fős apartman. A teljes házat (tehát mind a W1-et mind a W2-t együttesen) igénybe vevő szállóvendégeink számára a „Häuschen” (H) elnevezésű, különálló, szintén amerikai konyhás, nappali-étkezős de hálószoba, fürdő és WC nélküli 2 személyes parasztházat is rendelkezésre bocsátjuk minimális térítési díj ellenében.
Vendégházunk Harkány város országosan ismert és népszerű termálfürdőjétől mindössze 5 km-re található, így a "Das Blaue Haus" ideális szálláslehetőség egy hosszabb wellness-üdüléshez. Az Ormánság és a Baranya régió számos egyéb látnivalót és szabadidős tevékenységet kínál. Legyen szó kirándulásról, horgászatról, vásárlásról vagy Pécs, illetve Siklós történelmi helyeinek felfedezéséről, a híres villányi szőlőtermesztés borászainak finom borairól vagy egy magyar csárda tüzes konyhájáról, Vendégházunkban változatos és pihentető üdülésre számíthat!
A Vendégházban 10 fix férőhely van, amely 2+1 pótággyal kiegészíthető, a Häuschen további 2 férőhelyet biztosít (összesen tehát 15 személy elhelyezése megoldható).&nbsp;&nbsp;
            </p>
          )}
        </History>
        <SublineWrapper isMobile={isMobile}>
          <h3>{isGerman ? 'Video' : 'Video'}</h3>
        </SublineWrapper>
        <SublineWrapper />
        <VideoWithConsent isGerman  = {isGerman}/>
        <SublineWrapper />
        <CompleteHouse isGerman={isGerman} />
        <MarginWrapper isMobile={isMobile}>
          <h3>{isGerman ? 'Wohnung 1' : 'Lakás 1'}</h3>{' '}
        </MarginWrapper>
        <Gallery images={innerBigImages} />
        <FlatOne isGerman={isGerman}/>
        <ApartmentOneHungarian
         isGerman={isGerman}
          flat={isGerman ? 'Wohnung 1' : 'Lakás 1'}
          flatPrice={'60'}
          discount={'10'}
          smallFlat={'5'}
          isMobile = {isMobile}
        />
        <MarginWrapper isMobile={isMobile}>
          <h3>{isGerman ? 'Wohnung 2' : 'Lakás 2'}</h3>{' '}
        </MarginWrapper>
        <Gallery images={innerSmallImages} />
        <FlatTwo  isGerman={isGerman} />
        <ApartmentTwoHungarian
          isGerman={isGerman}
          flat={isGerman ? 'Wohnung 1' : 'Lakás 1'}
          flatPrice={'60'}
          discount={'10'}
          smallFlat={'5'}
          isMobile = {isMobile}
        />
      </Container>
    </Wrapper>
  );
}

const History = styled.div`
  padding: ${({ isMobile }) => (isMobile ? '' : '25px')};
  p {
    color: white;
    padding: 0 25px;
    margin-bottom: 3rem;
    text-align: justify;
  }
`;

const SublineWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ isMobile }) => (isMobile ? '' : '25px')};
  h3 {
    color: white;
    margin: 0;
  }
`;

const MarginWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3rem;
  padding: ${({ isMobile }) => (isMobile ? '' : '25px')};
  h3 {
    color: white;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;

  h3 {
    padding: 0 25px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  max-width: 1000px;
  background: var(--clr-bg);
  box-shadow: var(--bs2);
  border-radius: 10px;
  overflow-x: hidden;
  padding: 50px 0 0 0;
  h2 {
    color: white;
    text-align: left;
  }
`;
