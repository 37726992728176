import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { ArrowDropDownCircle } from '@styled-icons/material-outlined/ArrowDropDownCircle';


const ApartmentOneHungarian = ({ isMobile, isLast, isGerman }) => {
    const [isDescription, setIsDescription] = useState(false);
  
    return (
      <>
        <DropdownWrapper isMobile={isMobile} isLast={isLast}>
          <DescriptionWrapper isMobile={isMobile}>
            <Subline>{isGerman ? 'Preise' : 'Árak'}</Subline>
            <DropdownDescription
              onClick={() => setIsDescription(!isDescription)}
              isDescription={isDescription}
            />
          </DescriptionWrapper>
        </DropdownWrapper>
        <PriceWrapper
          isDescription={isDescription}
          isMobile={isMobile}
          isLast={isLast}
        >
          <div>
            <Subline>
              {isGerman ? 'W1 (Familie, 70 m²) - mindestens 2 Nächte' : 'W1 (családi, 70 nm) - minimum 2 éjszaka'}
              <br/>
            </Subline>
            <Subline>
                      {isGerman ? 'Die Preise beinhalten nicht die Kursteuer, die für jeden Gast über 14 Jahren pro Nacht 0,5 € beträgt.' : 
                      'Az árak nem tartalmazzák az IFA összeget, amely minden 14 év feletti vendég esetén 200 Ft.- éjszakánként.'}
                    </Subline>
                    <StyledTable>
              <tbody>
                <StyledTR>
                  <td>{isGerman ? 'Grundpreis' : 'alapár'}</td>
                  <td>{isGerman ? '6 Personen' : '6 fő'}</td>
                  <td>80 €</td>
                </StyledTR>
                <StyledTR>
                  <td></td>
                  <td>{isGerman ? '5 Personen' : '5 fő'}</td>
                  <td>70 €</td>
                </StyledTR>
                <StyledTR>
                  <td></td>
                  <td>{isGerman ? '4 Personen' : '4 fő'}</td>
                  <td>60 €</td>
                </StyledTR>
                <StyledTR>
                  <td>{isGerman ? 'Preis für Zusatzbett' : 'pótágyazott ár'}</td>
                  <td>{isGerman ? '6+1 Personen' : '6+1 fő'}</td>
                  <td>90 €</td>
                </StyledTR>
              </tbody>
            </StyledTable>
  
            <Subline>
                      {isGerman ? 'Für Gruppen- oder Langzeitbuchungen kontaktieren Sie uns bitte per E-Mail für individuelle Preise.' : 
                      'Csoportos vagy hosszabb időtartamra szóló foglalás esetén egyéni árakért kérje ajánlatunkat e-mail-ben!'}
                    </Subline>
          </div>
        </PriceWrapper>
      </>
    );
  };
  

        const ApartmentTwoHungarian = ({ isMobile, isLast, isGerman }) => {
            const [isDescription, setIsDescription] = useState(false);
          
            return (
              <>
                <DropdownWrapper isMobile={isMobile} isLast={isLast}>
                  <DescriptionWrapper isMobile={isMobile}>
                    <Subline>{isGerman ? 'Preise' : 'Árak'}</Subline>
                    <DropdownDescription
                      onClick={() => setIsDescription(!isDescription)}
                      isDescription={isDescription}
                    />
                  </DescriptionWrapper>
                </DropdownWrapper>
                <PriceWrapper
                  isDescription={isDescription}
                  isMobile={isMobile}
                  isLast={isLast}
                >
                  <div>
                    <Subline>{isGerman ? 'W2 (Studio, 30 m²) - mindestens 2 Nächte' : 'W2 (stúdió, 30 nm) - minimum 2 éjszaka'}</Subline>
                    <Subline>
                      {isGerman ? 'Die Preise beinhalten nicht die Kursteuer, die für jeden Gast über 14 Jahren pro Nacht 0,5 € beträgt.' : 
                      'Az árak nem tartalmazzák az IFA összeget, amely minden 14 év feletti vendég esetén 200 Ft.- éjszakánként.'}
                    </Subline>
                    <StyledTable>
                      <tbody>
                        <StyledTR>
                          <td>{isGerman ? 'Basispreis' : 'alapár'}</td>
                          <td>{isGerman ? '4 Personen' : '4 fő'}</td>
                          <td>50 €</td>
                        </StyledTR>
                        <StyledTR>
                          <td></td>
                          <td>{isGerman ? '3 Personen' : '3 fő'}</td>
                          <td>40 €</td>
                        </StyledTR>
                        <StyledTR>
                          <td></td>
                          <td>{isGerman ? '2 Personen' : '2 fő'}</td>
                          <td>30 €</td>
                        </StyledTR>
                        <StyledTR>
                          <td>{isGerman ? 'Preis für zusätzliches Bett' : 'pótágyazott ár'}</td>
                          <td>{isGerman ? '4+1 Personen' : '4+1 fő'}</td>
                          <td>60 €</td>
                        </StyledTR>
                      </tbody>
                    </StyledTable>
                    <Subline>
                      {isGerman ? 'Für Gruppen- oder Langzeitbuchungen kontaktieren Sie uns bitte per E-Mail für individuelle Preise.' : 
                      'Csoportos vagy hosszabb időtartamra szóló foglalás esetén egyéni árakért kérje ajánlatunkat e-mail-ben!'}
                    </Subline>
                  </div>
                </PriceWrapper>
              </>
            );
          };
          






export default ApartmentOneHungarian;
export  {ApartmentTwoHungarian};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse; /* Remove space between borders */
  margin-top: 1rem;

tbody{
    td {
        padding: 8px; /* Padding for table cells */
        text-align: left; /* Align text to the left */
     
      }
}
`;


const StyledTR = styled.tr`
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  border-bottom: 1px solid #ccc; /* Divider for rows */

  &:last-child {
    border-bottom: none; /* No divider for the last row */
  }

`;

const StyledTableData = styled.td`
  padding: 8px; /* Padding for table cells */
  text-align: left; /* Align text to the left */
  color: #333; /* Text color */
`;

const Subline = styled.p`
  font-weight: bold;
  letter-spacing: 1px;
  color: white;

`;

const Price = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 18rem;
  margin-bottom: 1rem;

  p {
    margin: 0;
    color: white;
  }

  p:first-child {
    padding-left: ${({ isMobile }) => (isMobile ? '25px ' : '50px')};
  }
`;

const PriceWrapper = styled.div`
  display: ${({ isDescription }) => (isDescription ? 'flex' : 'none')};
  padding: ${({ isMobile }) => (isMobile ? '0 0 0 25px' : '0 0 0 50px')};
  margin-bottom: ${({ isLast }) => (isLast ? '3rem' : '')};
 
`;

const DropdownWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-wrap: ${({ isMobile }) => (isMobile ? 'wrap' : 'wrap')};
  max-width: 1000px;
  margin-bottom: ${({ isLast }) => (isLast ? '3rem' : '')};
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 13rem;
  margin-bottom: 1rem;
  p {
    line-height: 1.75;
    text-align: left;
    padding: ${({ isMobile }) => (isMobile ? '0 0 0 25px' : '0 0 0 50px')};
    margin: 0;
    color: white;
  }
`;

const DropdownDescription = styled(ArrowDropDownCircle)`
  color: white;
  width: 25px;
  height: 25px;
  transition: transform 550ms;
  cursor: pointer;
  transform: ${({ isDescription }) => (isDescription ? 'rotate(-180deg)' : '')};
`;

const Wrapper = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
`;