import { createGlobalStyle } from 'styled-components';
import background from './assets/background.jpg';

export const GlobalStyles = createGlobalStyle`






*,
*::before,
*::after{
  box-sizing: border-box;

}



:root {
  --ff-primary: Roboto, sans-serif;
  --fw-reg: 300;
  --fw-bold: 900;
  
  /* --clr-bg: linear-gradient(to right, #3a7bd5, #3a6073); */
  --clr-bg: rgb(5, 82, 142, 0.6);
  --clr-tools: rgb(5, 82, 142, 0.6);
  --clr-grdbg:linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 0.35) 50%,
    rgba(255, 255, 255, 0.35) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  --clr-btn-hov-dismiss:hsl(353, 85%, 53%);
  --clr-btn-dismiss:hsl(353, 85%, 53%, 0.7);
  --clr-btn-hov-agree: hsl(119, 42%, 45%);
  --clr-btn-agree:hsl(119, 42%, 45%, 0.7);
  --clr-btn-hov:hsla(360, 100%, 100%, 0.15);
  --clr-bg-opac: rgba(0, 41, 87, .5);
  --clr-bg-modal: rgba(0, 0, 0, 0.7);
  --clr-toolbar: rgb(58, 109, 162, 0.7);
  --clr-hov: hsla(360, 100%, 100%, 0.15);
  --clr-opac: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0.4) 100%);

  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;


  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 2.5),
  0.125em 0.125em 0.25em rgba(0, 0, 0, .15); 
  --bs2: 0px 0px 10px rgba(0, 0, 0, 0.5);
}



@media(min-width: 800px){
  --fs-h1: 4.5rem;
  --fs-h2: 3.75rem;
  --fs-h3: 1.5rem;
  --fs-body: 1.125rem;
} 

html, body, #root {
  min-height: 100vH;

}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-family: Roboto, sans-serif;
  font-size: var(--fs-body);
  margin: 0;
  line-height: 1.6;
  /* background-image: url(${background}); */
  /* background-position: center 25%; */
  /* background-size: 3648px 5472px; */
  /* background-position: center 10%; */
  /* background-repeat: no-repeat; */

  
} 
img {
  display: block;
  max-width: 100%;
}
strong {
  font-weight: var(--fw-bold);
}
section {
  /* padding: 2em 0.6em; */
  background: var(--clr-bg);
margin:0;
padding:0;
}

h1,h2,h3 {
  line-height: 1;
  margin: 0;
  color: white;
  letter-spacing: 0.05em;
}

h1 {
  font-size: var(--fs-h1);
}

h2 {
  font-size: var(--fs-h2);
}

h3 {
  font-size: var(--fs-h3);
}

`;
