import styled from 'styled-components';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { Play as Circle } from '@styled-icons/bootstrap/Play';
import { Homeassistantcommunitystore as HomePic } from '@styled-icons/simple-icons/Homeassistantcommunitystore';
// import { Googlemaps } from '@styled-icons/simple-icons/Googlemaps';
import { Swim } from '@styled-icons/boxicons-regular/Swim';
import { Backpack } from '@styled-icons/fluentui-system-filled/Backpack';
import { Contact } from '@styled-icons/boxicons-solid/Contact';
import stencil from '../../assets/stencil.png';
import CountryButton from '../CountryButton';


export default function Menu({ open, setOpen, isGerman, setIsGerman }) {
  const location = useLocation();
  let history = useHistory();
  const isHome = location.pathname === '/';
  const isAbout = location.pathname === '/blaueshaus';
  const isHarkany = location.pathname === '/harkany';
  const isTrips = location.pathname === '/ausfluege';
  const isContact = location.pathname === '/contact';

  const clickHandler = () => {
    history.push('/');
    setOpen(false);
  };

  return !isMobile ? (
    <StyledMenu open={open}>
      <MenuWrapper>
        <BurgerLink to="/">
          <HomeWrap isHome={isHome}>
            <CloseWrapper>
              <p>{isGerman ? 'Start' : 'Kezdőlap'}</p>
            </CloseWrapper>
          </HomeWrap>
        </BurgerLink>
        <BurgerLink to="/blaueshaus">
          <AboutWrap isAbout={isAbout}>
            <CloseWrapper>
              <p>{isGerman ? 'Das Haus' : 'A ház'}</p>
            </CloseWrapper>
          </AboutWrap>
        </BurgerLink>
        <BurgerLink to="/harkany">
          <HarkanyWrap isHarkany={isHarkany}>
            <CloseWrapper>
              <p>{isGerman ? 'Harkány' : 'Harkány'}</p> {/* Harkány is the same in both languages */}
            </CloseWrapper>
          </HarkanyWrap>
        </BurgerLink>
        <BurgerLink to="/ausfluege">
          <TripsWrap isTrips={isTrips}>
            <CloseWrapper>
              <p>{isGerman ? 'Ausflüge' : 'Kirándulások'}</p>
            </CloseWrapper>
          </TripsWrap>
        </BurgerLink>
        <BurgerLink to="/contact">
          <ContactWrap isContact={isContact}>
            <CloseWrapper>
              <p>{isGerman ? 'Kontakt' : 'Kapcsolat'}</p>
            </CloseWrapper>
          </ContactWrap>
        </BurgerLink>
        <CountryButton isGerman={isGerman} onClick={() => setIsGerman(!isGerman)} />
      </MenuWrapper>
    </StyledMenu>
  ) : (
    <StyledMobileMenu open={open}>
      
      <img src={stencil} alt="" onClick={clickHandler} />
     
      <MobileMenuWrapper isTablet={isTablet}>
        <BurgerLink to="/blaueshaus" isFirst={!isTablet}>
          <CloseMobileWrapper onClick={() => setOpen(false)}>
            <AboutIcon />
            <p>{isGerman ? 'Das Haus' : 'A ház'}</p>
          </CloseMobileWrapper>
        </BurgerLink>
        <BurgerLink to="/harkany">
          <CloseMobileWrapper onClick={() => setOpen(false)}>
            <SwimIcon />
            <p>{isGerman ? 'Harkány' : 'Harkány'}</p> {/* Harkány is the same in both languages */}
          </CloseMobileWrapper>
        </BurgerLink>
        <BurgerLink to="/ausfluege">
          <CloseMobileWrapper onClick={() => setOpen(false)}>
            <VitaIcon />
            <p>{isGerman ? 'Ausflüge' : 'Kirándulások'}</p>
          </CloseMobileWrapper>
        </BurgerLink>
        <BurgerLink to="/contact">
          <CloseMobileWrapper onClick={() => setOpen(false)}>
            <ContactIcon />
            <p>{isGerman ? 'Kontakt' : 'Kapcsolat'}</p>
          </CloseMobileWrapper>
        </BurgerLink>
      </MobileMenuWrapper>    
    </StyledMobileMenu>
  );
}

const FlagWrap = styled.div`
display: flex;
flex-direction:column;
width: 100%; 
justify-content: flex-end;
padding: 0.75rem 0.75rem;
`;

const HomeWrap = styled.div`
  background: ${({ isHome }) => (isHome ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const ContactWrap = styled.div`
  background: ${({ isContact }) => (isContact ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const AboutWrap = styled.div`
  background: ${({ isAbout }) => (isAbout ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const TripsWrap = styled.div`
  background: ${({ isTrips }) => (isTrips ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;


const HarkanyWrap = styled.div`
  background: ${({ isHarkany }) => (isHarkany ? 'var(--clr-hov)' : '')};
  transition-timing-function: ease-in-out;
  transition-property: background;
  transition-duration: 300ms;
  p {
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const StyledMenu = styled.nav`
  background: var(--clr-bg);
  width: 100%;
  box-shadow: var(--bs2);
  margin-bottom: 3rem;
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  flex-wrap: wrap;
`;

const StyledMobileMenu = styled.nav`
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  will-change: transform;
  text-align: left;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  width: 40vh;
  z-index: 3;
  box-shadow: var(--bs2);
  @media (max-width: 800px) {
    width: 35vh;
  }
  @media (max-width: 500px) {
    width: 30vh;
  }

  img {
    position: absolute;
    margin-bottom: 2.5rem;
  }
`;

const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: ${({ isTablet }) => (isTablet ? '30%' : '25%')};
  height: 80%;
  width: 100%;
`;

const BurgerLink = styled(NavLink)`
  text-decoration: none;
  margin-top: ${({ isFirst }) => isFirst && '2.5rem'};
`;

const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 8rem;
  color: white;
  p {
    font-size: 1.2rem;
  }
`;

const CloseMobileWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;

  p {
    color: rgb(5, 82, 142);
  }

  &:active,
  &:hover {
    background-color: var(--clr-hov);
  }
`;

const ContactIcon = styled(Contact)`
  color: rgb(5, 82, 142);
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;

const HomeIcon = styled(Circle)`
  color: rgb(5, 82, 142);
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;

const VitaIcon = styled(Backpack)`
  color: rgb(5, 82, 142);
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;

const AboutIcon = styled(HomePic)`
  color: rgb(5, 82, 142);
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;


const SwimIcon = styled(Swim)`
color: rgb(5, 82, 142);
  height: 30px;
  margin: 0 20px;
  width: 30px;
`;
