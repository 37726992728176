import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Gallery from '../Components/Gallery';
import { villany } from '../data/images';
import { siklos } from '../data/images';
import { pecs } from '../data/images';
import { fishing } from '../data/images';

export default function Trips({ isGerman, isMobile }) {
  const target = isMobile ? '' : '_blank';
  const rel = isMobile ? '' : 'noopener noreferrer';

  return (
    <Wrapper>
      <InnerWrapper>
        <GalleryContainer isMobile={isMobile}>
          <GalleryWrapper>
            <h3>Pécs</h3>
            
              {isGerman ? 
                    <p> Pécs ist mit 142.873 Einwohnern (Stand 2019) die fünftgrößte Stadt
                     Ungarns. Die Stadt liegt am Fuße des Mecsek-Gebirges und war im
                     Jahr 2010 Kulturhauptstadt Europas. Die zahlreichen Baudenkmäler
                     verleihen dem Ort eine mediterrane Atmosphäre. Der Weg nach
                     Budapest per Autobahn beträgt etwa 220 km und kann per bestehendem
                     IntercityExpress in etwa 2 Stunden zurück gelegt werden. Zum
                     Balaton sind es etwa 110 km, zur kroatischen Grenze sind es ca. 35
                     km.</p>
               : <p>
              Pécs közel 140 ezer lakosával Magyarország ötödik legnagyobb városa. 
              A Mecsek lábánál fekvő város 2010-ben Európa Kulturális Fővárosa volt. 
              A számtalan építészeti emlék mediterrán hangulatot kölcsönöz a helynek. 
              Budapest az M6 autópályán körülbelül 240 km, amely távolságot vasúton az 
              IntercityExpress körülbelül 2 óra alatt teszi meg. A várostól a Balaton 
              110 km, a horvát határ 35 km.
                </p>}
                

            <ul>
              <li>
                <a href={isGerman ? "https://www.iranypecs.hu/" : "https://www.iranypecs.hu/hu"} target={target} rel={rel}>
                  {isGerman ? 'Tourismus Informationen der Stadt Pécs' : 'Turisztikai információk Pécsről'}
                </a>
              </li>
              <li>
                <a href="https://de.wikipedia.org/wiki/P%C3%A9cs" target={target} rel={rel}>
                  {isGerman ? 'Wikipedia – Pécs mit Sehenswürdigkeiten' : 'Pécs látnivalói – Wikipédia'}
                </a>
              </li>
            </ul>
          </GalleryWrapper>
        </GalleryContainer>
        <Gallery images={pecs} />

        {/* Villány section */}
        <GalleryContainer isMobile={isMobile}>
          <GalleryWrapper>
            <h3>Villány</h3>
            {isGerman ? 
                    <p> Die „Stadt der Trauben und Weine“ ist ein Bahnknotenpunkt und
                    bietet zahlreiche Sehenswürdigkeiten. Sie ist auch heute noch von
                    Donauschwaben bewohnt, die nach den Türkenkriegen von Maria
                    Theresia dort angesiedelt wurden. Sie brachten neue Rebsorten nach
                    Ungarn mit (z. B. Blauer Portugieser) und erarbeiteten sich
                    einigen Wohlstand. Der Wein aus Villány und Umgebung hat sich auch
                    außerhalb Ungarns einen guten Ruf erworben. Vor allem Budapester
                    Weinkenner jedoch sind bereit, Höchstpreise für Weine aus den
                    Spitzenweingütern aus Villány zu zahlen. An der Weinstraße
                    Villány-Siklós befinden sich unzählige Weinkeller. Im Gegensatz
                    zum benachbarten Weißweinort Siklós, ist Villány wegen seiner
                    Rotweine berühmt, die dem Ort den Spitznamen „Bordeaux des Ostens“
                    eingebracht haben. In der Nähe des Ortes befindet sich im
                    südwestlichen Teil der Gemarkung, an dem kegelförmigen Berg
                    Szársomlyó, ein Skulpturenpark, der durch ein Bildhauersymposion
                    Villány im Jahre 1968 entstand.</p>
               : <p>
                A "Szőlő és borok városa", a Villányi-hegység és a „Harsányok” (Kisharsány, Nagyharsány) 
                számos látnivalót kínálnak. A várost ma is főként az ún. „dunai svábok” és leszármazottaik 
                lakják, akiket Mária Terézia telepített ide a török ​​háborúk után. Általuk gazdagodott a vidék 
                és Magyarország új szőlőfajtákkal, mint pl. a Portugieser (Kékoportó) és általuk ért el a település 
                és a környék jelentős fellendülést a török pusztítás után. Ez hazánk legmelegebb, legnaposabb borvidéke, 
                hosszú száraz nyarakkal és tenyészidővel.
 A villányi és környékbeli borok határainkon túl is nagy hírnévre tettek szert, a borínyencek Villány 
 legjobb pincészeteinek boraiért csúcsárakat is hajlandóak fizetni. A Villány-Siklós borúton számtalan hívogató borospince található, ahol a borkínálaton túl a nemes gazdák nagy szeretettel osztják meg a vendégekkel a szőlő- és bortermesztés „nyilvános titkait”. A szomszédos Siklós inkább fehérborairól, Villány jellegzetesen vörösborairól híres. Nekik köszönheti a város a „Kelet Bordeaux”-ja becenevet. A város közelében, a járás délnyugati részén, a kúpos Szár-somlyó hegyen található a szoborpark, amelyet egy villányi szobrászszimpózium hozott létre 1968-ban.
                </p>}
            <ul>
              <li>
                <a href={isGerman ? "https://villanyiborvidek.hu/de" : "https://villanyiborvidek.hu/"} target={target} rel={rel}>
                  {isGerman ? 'Website Weinbaugebiets Villany' : 'A Villányi borvidék'}
                </a>
              </li>
              <li>
                <a href={isGerman ? "https://de.wikipedia.org/wiki/Vill%C3%A1ny"  : "https://hu.wikipedia.org/wiki/Vill%C3%A1ny"}target={target} rel={rel}>
                  {isGerman ? 'Quelle' : 'Villány a Wikipédián'}
                </a>
              </li>
            </ul>
          </GalleryWrapper>
        </GalleryContainer>
        <Gallery images={villany} />

        {/* Siklos section */}
        <GalleryContainer isMobile={isMobile}>
          <GalleryWrapper>
            <h3>Siklos</h3>
            {isGerman ? 
                    <p> Die Stadt Siklos liegt 31 km südlich der Großstadt Pécs am Südhang
                    des Villány-Gebirges, das sich mit einigen Kuppen etwa 300 m über
                    das umliegende Draubecken erhebt. Das bedeutendste Baudenkmal der
                    Stadt ist die Burg, die erstmals 1294 urkundlich erwähnt wurde.
                    Die Burg Siklós gehört zu den am besten erhaltenen historischen
                    Bauten des Landes; an ihr können fast alle Epochen der
                    Baugeschichte beobachtet werden: von der Romanik und Gotik bis zum
                    Barock. Für seine Wallfahrten berühmt ist die barocke
                    Franziskanerkirche von Siklós-Máriagyűd mit ihrem noch gotischen
                    Chor. Ihre Geschichte lässt sich bis zur Zeit der Römer
                    zurückführen. Um 1800 hat Papst Pius VII. Máriagyűd offiziell als
                    Wallfahrtsort anerkannt.</p>
               : <p>
Siklós városa Vendégházunktól 10 km-re található a Villányi-hegység déli lejtőjén, 
amely mintegy 300 méterrel emelkedik a környező Dráva-medence fölé. A város 
legjelentősebb építészeti emléke a kastély, amelyet először 1294-ben említenek 
oklevelek. A siklósi vár az ország egyik legjobb állapotban fennmaradt történelmi 
épülete, amelyen a romántól és a gótikától a barokkig az építészettörténet szinte
 minden korszaka megfigyelhető.
                </p>}
            <ul>
              <li>
                <a href={isGerman ? "http://www.siklos.hu/" : "http://www.siklos.hu/"} target={target} rel={rel}>
                  {isGerman ? 'Website der Stadt Siklos' : 'Siklós weboldala'}
                </a>
              </li>
              <li>
                <a href={isGerman ? "https://de.wikipedia.org/wiki/Sikl%C3%B3s" : "https://hu.wikipedia.org/wiki/Sikl%C3%B3s"} target={target} rel={rel}>
                  {isGerman ? 'Quelle' : 'Siklós a Wikipédián'}
                </a>
              </li>
            </ul>
            
            
         {  !isGerman ?  <>
            <p>Máriagyűd<br/> <br/> 

Máriagyűd Siklós város része, korábban önálló község volt, Vendégházunktól 12 km-re fekszik. Neve 1934-ig Gyűd volt, 1977-ben csatolták Siklóshoz. A 2001-ben mintegy 1500 lelket számláló Máriagyűd híres Mária-kegyhely és búcsújáróhely. A kegytemplomtól keletre található Szentkút, amelynek vizét a „gyűdi korsókban” vitték haza az idelátogató zarándokok. Máriagyűdön minden Márianapkor és jelesebb katolikus ünnep alkalmával búcsút tartanak, évente kb. 25 alkalommal. Az évente idelátogató zarándokok számát ötszázezerre becsülik.
Máriagyűdöt hivatalosan VII. Piusz pápa ismerte el zarándokhelynek 1805-ben. A kereszt alaprajzú mai templom 1742-ben épült, Batthyány Kázmér gróf, horvát bán adományából. 1995-1996-ban nagyarányú külső és belső műemléki felújításokat eszközöltek Pavlekovics Ferenc plébános ideje alatt. Ekkor készültek a templom belső falfestményei, amelyek Szilágyi András és testvére Szilágyi Domokos művei.
2008. szeptember 14-én XVI. Benedek pápa bazilika címet adományozott a kegytemplomnak.</p>
<ul>
              <li>
                <a href="https://www.wikiwand.com/hu/Gy%25C5%25B1d" target={target} rel={rel}>
                Máriagyűd a Wikiwand-on
                </a>
              </li>
              <li>
                <a href="http://www.mariagyud.hu/" target={target} rel={rel}>
                Máriagyűd weboldala
                </a>
              </li>
              <li>
                <a href="https://hu.wikipedia.org/wiki/M%C3%A1riagy%C5%B1d" target={target} rel={rel}>
                Máriagyűd a Wikipédián
                </a>
              </li>
            </ul>
            </> : <></>}
          </GalleryWrapper>
        </GalleryContainer>
        <Gallery images={siklos} />
        <GalleryContainer isMobile={isMobile}>
        <GalleryWrapper>
  <h3>{isGerman ? 'Angeln am See Kovácshida' : 'Horgászat a kovácshidai tavakon'}</h3>
  <p>
    {isGerman
      ? 'Nur wenige hundert Meter vom Blauen Haus entfernt, befindet sich der See Kovácshida. Er eignet sich nicht nur hervorragend zum Angeln, sondern lädt auch zu idyllischen Spaziergängen an lauen Sommerabenden ein.'
      : 'Vendégházunktól mindössze néhány száz méterre találhatók a BCM Kovácshida Horgászegyesület kezelésében lévő horgásztavak, amelyek nemcsak horgászoknak kínálnak ideális lehetőséget, de idilli sétákra is csábítanak tavasztól őszig.'}
  </p>
  <ul>
    <li>
      {' '}
      <a
        href="http://www.kovacshidaitavak.hu/"
        target={target}
        rel={rel}
      >
        {isGerman
          ? 'Website des Sees Kovácshida'
          : 'A Kovácshidai tavak weboldala'}
      </a>{' '}
    </li>
  </ul>
</GalleryWrapper>

        </GalleryContainer>
        <Gallery images={fishing} />
      </InnerWrapper>
    </Wrapper>
  );
}

const GalleryContainer = styled.div`
  max-width: 1000px;
  padding: ${({ isMobile }) => (isMobile ? '' : '25px')};
  overflow-x: hidden;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  padding: 50px 0 0 0;
  p {
    text-align: justify;
    color: white;
    padding: 25px;
  }
  a {
    color: white;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  background: var(--clr-bg);
  box-shadow: var(--bs2);
  border-radius: 10px;
  /* padding: 25px; */
  h3 {
    padding: 0 25px;
  }
`;

const GalleryWrapper = styled.div`
  ul {
    color: white;
  }
  li {
    padding: 10px;
  }
`;
