import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export default function DatenschutzHungarian() {
  const target = isMobile ? '' : '_blank';
  const rel = isMobile ? '' : 'noopener noreferrer';

  return (
    <Wrapper isMobile={isMobile}>
      <InnerWrapper>
        <Block>
        <h3>Az adatvédelmi törvények, különösen az EU általános adatvédelmi rendelete (GDPR) értelmében az adatvédelemért felelős személy</h3>
<p>Dr. Judit Molnar-Toriz<br />Eichgasse 2<br />D-72379 Hechingen, Németország</p>
<p>Tiltakozását címezze: <br/>  Dr. Schneider Csaba<br/><a href="mailto:dr.schneider.csaba@gmail.com">dr.schneider.csaba@gmail.com</a></p>

        </Block>
        <Block>
        <h3>Az Ön érintetti jogai</h3>
<p>Az adatvédelmi megbízottunk által megadott elérhetőségen bármikor gyakorolhatja az alábbi jogait:</p>
<ul>
    <li>Az általunk tárolt adataival és azok feldolgozásával kapcsolatos információk (GDPR 15. cikk),</li>
    <li>A helytelen személyes adatok helyesbítése (GDPR 16. cikk),</li>
    <li>Az általunk tárolt adatainak törlése (GDPR 17. cikk),</li>
    <li>Az adatkezelés korlátozása, ha törvényi kötelezettségek miatt még nem törölhetjük adatait (GDPR 18. cikk),</li>
    <li>Tiltakozás az Ön adatainak általunk történő feldolgozásával szemben (GDPR 21. cikk) és</li>
    <li>Adathordozhatóság, amennyiben hozzájárult az adatkezeléshez, vagy szerződést kötött velünk (GDPR 20. cikk).</li>
</ul>

<p>Ha adatkezelési hozzájárulását megadta nekünk, azt a jövőre vonatkozólag bármikor visszavonhatja.</p>

<p>Ön jogosult arra, hogy panaszt tegyen a felügyeleti hatóságnál – különösen a szokásos tartózkodási helye, a munkahelye vagy a feltételezett jogsértés helye szerinti tagállamban –, ha az Ön megítélése szerint az Önre vonatkozó személyes adatok kezelése sérti a GDPR rendelkezéseit.</p>
<p>Magyarországon az illetékes felügyeleti hatóság: Nemzeti Adatvédelmi és Információszabadság Hatóság</p>
< ul>
    <li>web: <a href="http://naih.hu/" target="_blank" rel="noopener noreferrer">http://naih.hu/</a></li>
    <li>Cím: 1363 Budapest, Pf.: 9.</li>
    <li>Telefon: <a href="tel:+36-1-391-1400">+36-1-391-1400</a></li>
    <li>Fax: <a href="fax:+36-1-391-1410">+36-1-391-1410</a></li>
    <li>E-mail: <a href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a></li>
</ ul>

<p>Ön jogosult a hatékony bírósági jogorvoslatra a felügyeleti hatóság Önre vonatkozó, jogilag kötelező erejű döntésével szemben.</p>
<p>Ön jogosult a hatékony bírósági jogorvoslatra, ha az illetékes felügyeleti hatóság nem foglalkozik a panasszal, vagy három hónapon belül nem tájékoztatja Önt a benyújtott panasszal kapcsolatos eljárási fejleményekről vagy annak eredményéről.</p>
<p>A felügyeleti hatósággal szembeni eljárást a felügyeleti hatóság székhelye szerinti tagállam bírósága előtt lehet megindítani.</p>

        </Block>
        <Block>
        <h3>Általános információk gyűjtése, amikor meglátogatja weboldalunkat</h3>
<p>Az adatkezelés típusa és célja:</p>
<p>Amikor belép weboldalunkra, azaz ha nem regisztrál vagy más módon nem ad meg információkat, az általános jellegű információkat automatikusan gyűjtjük. Ezek az információk (szerver naplófájlok) tartalmazzák a webböngésző típusát, a használt operációs rendszert, az internetszolgáltató domain nevét, az Ön IP-címét és hasonlókat.</p>
<ul>
    <li>Problémamentes kapcsolat biztosítása a weboldallal,</li>
    <li>Weboldalunk zökkenőmentes használatának biztosítása,</li>
    <li>A rendszer biztonságának és stabilitásának értékelése és</li>
    <li>Weboldalunk optimalizálása érdekében.</li>
</ul>
<p>Adatait nem arra használjuk, hogy következtetéseket vonjunk le személyesen Önről. Az ilyen típusú információkat kizárólag anonim módon statisztikailag értékeljük weboldalunk és a mögötte lévő technológia optimalizálása érdekében.</p>
        </Block>
        <Block>
        <h3>Jogalap és jogos érdek</h3>
<p>Az adatkezelés a GDPR 6. cikke (1) bekezdésének f) pontja szerint történik, a weboldalunk stabilitásának és funkcionalitásának javításához fűződő jogos érdekünk alapján.</p>
        </Block>
        <Block>
        <p>Az adatok címzettjei lehetnek műszaki szolgáltatók, akik szerződéses adatfeldolgozóként járulnak hozzá weboldalunk üzemeltetéséhez és karbantartásához.</p>
        </Block>
        <Block>
        <h3>Címzett</h3>
<p>Az adatok címzettjei lehetnek műszaki szolgáltatók, akik szerződéses adatfeldolgozóként járulnak hozzá weboldalunk üzemeltetéséhez és karbantartásához.</p>

        </Block>
        <Block>
        <h3>Adattárolás időtartama</h3>
<p>Az adatok törlésre kerülnek, amint arra már nincs szükség az adatgyűjtés céljára. Általában ez a helyzet a webhely biztosításához használt adatok esetében, amikor az adott munkamenet véget ért.</p>
        </Block>
        <Block>
        <h3>Szükséges vagy kötelező rendelkezés</h3>
<p>A fent említett személyes adatok megadását sem törvény, sem szerződés nem írja elő. Az IP-cím nélkül azonban weboldalunk szolgáltatása és működőképessége nem garantálható. Ezenkívül előfordulhat, hogy egyes szolgáltatások nem érhetők el vagy korlátozottak. Emiatt a kifogás kizárt.</p>
        </Block>

        <Block>
        <h3>Szkriptkönyvtárak használata (Google Webfonts)</h3>
<p>Annak érdekében, hogy tartalmainkat helyesen és grafikusan jelenítsük meg a böngészőkben, a Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; a továbbiakban: „Google”) „Google Web Fonts”-ját használjuk a betűtípusok megjelenítésére ezen a webhelyen.

A Google Web Fonts-ról további információ a  <a
              href="https://developers.google.com/fonts/faq"
              rel={rel}
              target={target}
            >
              https://developers.google.com/fonts/faq
            </a> oldalon és a Google adatvédelmi szabályzatában található: <a
              href="https://www.google.com/policies/privacy/"
              rel={rel}
              target={target}
            >
              https://www.google.com/policies/privacy/
            </a>. </p>
        </Block>

        <Block>
        <h3>A Google Maps használata</h3>
<p>Ezen az oldalon a Google Térképet használjuk. A Google Térképet a Google LLC üzemelteti. Ez lehetővé teszi, hogy interaktív térképeket mutassunk meg Önnek közvetlenül a weboldalon, és kényelmesen tudja használni a térkép funkciót. A Google általi adatkezelésről további információkat a Google adatvédelmi tájékoztatójában talál: https://policies.google.com/privacy . Itt az adatvédelmi központban is módosíthatja a személyes adatok védelmére vonatkozó beállításait. </p>
        <p>A Google termékekkel kapcsolatos saját adatainak kezelésével kapcsolatos részletes szabályzók itt találhatók: &nbsp;<a href="https://www.dataliberation.org" target={target} rel={rel}>
    link
</a>. &nbsp;


A weboldal meglátogatásával a Google információt kap arról, hogy Ön elérte weboldalunk megfelelő aloldalát. Ez attól függetlenül történik, hogy a Google biztosít-e felhasználói fiókot, amelyen keresztül bejelentkezik, vagy nincs felhasználói fiók. Ha be van jelentkezve a Google rendszerébe, adatai közvetlenül a fiókjához lesznek rendelve.

Ha nem szeretné, hogy profilja a Google-hoz legyen rendelve, a gomb aktiválása előtt ki kell jelentkeznie a Google-ból. A Google az Ön adatait használati profilként menti, és reklámozásra, piackutatásra és/vagy webhelyei igény szerinti kialakítására használja fel. Az ilyen értékelés különösen azért történik (még a be nem jelentkezett felhasználók számára is), hogy személyre szabott hirdetéseket jeleníthessen meg, és tájékoztassa Önt és a közösségi hálózat többi felhasználóját a weboldalunkon végzett tevékenységeiről. Önnek jogában áll tiltakozni ezen felhasználói profilok létrehozása ellen, ennek gyakorlásához kapcsolatba kell lépnie a Google-lal.</p>
        </Block>
       
       
        <Block>
        <h3>A hozzájárulás visszavonása</h3>
<p>A szolgáltató jelenleg nem kínál lehetőséget egyszerű leiratkozásra vagy adatátvitel blokkolására. Ha szeretné megakadályozni, hogy a weboldalunkon végzett tevékenységei nyomon követésre kerüljenek, kérjük, vonja vissza a hozzájárulását az adott süti kategóriához, vagy minden technikailag szükségtelen sütihez és adatátvitelhez a cookie hozzájárulási eszközben. Ebben az esetben azonban előfordulhat, hogy nem, vagy csak korlátozottan tudja használni a weboldalunkat.

tájékoztatójában talál:&nbsp;<a href="https://policies.google.com/privacy" target={target} rel={rel}>link</a>. &nbsp;
 . Itt az adatvédelmi központban is módosíthatja a személyes adatok védelmére vonatkozó beállításait.



A Google termékekkel kapcsolatos saját adatainak kezelésével kapcsolatos részletes szabályzók itt találhatók: &nbsp;<a href="https://www.dataliberation.org" target={target} rel={rel}>
    link
</a>. &nbsp;



A weboldal meglátogatásával a Google információt kap arról, hogy Ön elérte weboldalunk megfelelő aloldalát. Ez attól függetlenül történik, hogy a Google biztosít-e felhasználói fiókot, amelyen keresztül bejelentkezik, vagy nincs felhasználói fiók. Ha be van jelentkezve a Google rendszerébe, adatai közvetlenül a fiókjához lesznek rendelve.

Ha nem szeretné, hogy profilja a Google-hoz legyen rendelve, a gomb aktiválása előtt ki kell jelentkeznie a Google-ból. A Google az Ön adatait használati profilként menti, és reklámozásra, piackutatásra és/vagy webhelyei igény szerinti kialakítására használja fel. Az ilyen értékelés különösen azért történik (még a be nem jelentkezett felhasználók számára is), hogy személyre szabott hirdetéseket jeleníthessen meg, és tájékoztassa Önt és a közösségi hálózat többi felhasználóját a weboldalunkon végzett tevékenységeiről. Önnek jogában áll tiltakozni ezen felhasználói profilok létrehozása ellen, ennek gyakorlásához kapcsolatba kell lépnie a Google-lal.
 </p>
        </Block>
       
        <Block>
        <h3>A hozzájárulás visszavonása</h3>
<p> szolgáltató jelenleg nem kínál lehetőséget egyszerű leiratkozásra vagy adatátvitel blokkolására. Ha szeretné megakadályozni, hogy a weboldalunkon végzett tevékenységei nyomon követésre kerüljenek, kérjük, vonja vissza a hozzájárulását az adott süti kategóriához, vagy minden technikailag szükségtelen sütihez és adatátvitelhez a cookie hozzájárulási eszközben. Ebben az esetben azonban előfordulhat, hogy nem, vagy csak korlátozottan tudja használni a weboldalunkat.</p>
        </Block>

        <Block>
        <h3>Tájékoztatás a GDPR 21. cikke szerinti tiltakozási jogról</h3>
        <h3>Eseti tiltakozás joga</h3>
<p> Önnek joga van az Ön sajátos helyzetéből fakadó okokból bármikor tiltakozni az Önre vonatkozó személyes adatok kezelése ellen, amely a GDPR 6. cikk (1) bekezdés f) pontján (érdekmérlegelésen alapuló adatkezelés) nyugszik. Ez vonatkozik az ezen rendelkezésen alapuló profilalkotásra is a GDPR 4. cikkének 4. pontja értelmében.



Ha tiltakozik az adatkezelés ellen, a továbbiakban nem kezeljük személyes adatait, kivéve, ha bizonyítani tudjuk az adatkezelés olyan kényszerítő erejű jogos indokait, amelyek meghaladják az Ön érdekeit, jogait és szabadságait, vagy ha az adatkezelés jogi igények érvényesítésére, gyakorlására vagy védelmére szolgál.</p>
        </Block>

    
        <Block>
        <p>Tiltakozását címezze: <br/>  Dr. Schneider Csaba<br/><a href="mailto:dr.schneider.csaba@gmail.com">dr.schneider.csaba@gmail.com</a></p>
        </Block>
       
      </InnerWrapper>
    </Wrapper>
  );
}

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2rem;
  width: 90%;

  h3 {
    margin: 1rem 0;
    line-height: 1.1;
  }

  ul {
    color: white;
    padding: 0 1rem;
    margin: 1rem 0;

    li {
      margin-bottom: 1rem;
    }
  }

  p {
    text-align: justify;
    color: white;
    margin: 0;
  }

  a {
    color: white;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  box-shadow: var(--bs2);
  border-radius: 10px;
  overflow-x: hidden;
  padding: 50px 0 0 0;
  p {
    text-align: justify;
    color: white;
    padding: 25px 25px 0 25px;
  }
  a {
    color: white;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  background: ${({ isMobile }) =>
    isMobile ? 'rgb(71, 127, 172)' : 'var(--clr-bg)'};
  box-shadow: var(--bs2);
  border-radius: 10px;
  h3 {
    padding: 0 25px;
  }

  ul {
    margin-left: 50px;
  }
`;
