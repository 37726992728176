import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Gallery from '../Components/Gallery';
// import { links } from '../data/links';
import { harkany } from '../data/images';


export default function Harkany() {
  const target = isMobile ? '' : '_blank';
  const rel = isMobile ? '' : 'noopener noreferrer';

  // const imagesSport = links.sport;
  // const imagesThermal = links.thermal;

  return (
    <Wrapper>
      <InnerWrapper>
        <GalleryWrapper isMobile={isMobile}>
          <h3>Thermalbad</h3>
          <p>
          Seit mehr als 180 Jahren ist Harkány mit seinem 
            <a href= "https://harkanyfurdo.hu/de/" target={target} rel={rel}>
             Thermalbad
            </a>{' '}
             als ausgezeichneter Kurort bekannt. Im angenehm warmen Wasser (34 – 38 °C) kann man nicht nur entspannen: Es ist reich an besonders wirkungsvollen Inhaltsstoffen – ein Umstand der insbesondere bei Menschen mit gynäkologischen Beschwerden, Osteoporose, Rheuma oder Schuppenflechte großen Anklang findet. Daher übernehmen sogar manche Krankenkassen die Kosten für einen Besuch des Heilbads. Aufgrund der Wärme des Wassers sind die äußeren Becken des Thermalbads auch während des Winters geöffnet. Auf dem 13,5 Hektar großen Gelände sorgen zudem Saunanlagen, Sprudel- und Massagebecken, sowie ein Kosmetik- und Massagesalon für einen rundum gelungenen Wellnessaufenthalt.
            &nbsp;&nbsp;{' '}
            <a
              href= "https://harkanyfurdo.hu/de/heilbad" 
              target={target}
              rel={rel}
            >
             Mehr Informationen
            </a>{' '}
          </p>
        </GalleryWrapper>

        <Gallery images={harkany} />

        <GalleryWrapper isMobile={isMobile}>
          <h3>Erlebnisbad</h3>
          <p>
          Der Kurort bietet nicht nur ein Thermalbad: Von Mai bis Oktober erwarten Sie auf dem reichlich begrünten Gelände mehrere Schwimm- und Sportbecken mit Rutschanlagen. Für kleine Kinder stehen zudem Plantschbecken zum austoben bereit. Auch außerhalb des Wassers weiß Harkány auf ganzer Linie zu unterhalten: Beachvolleyball- sowie Fußballfelder, von Animatoren geleitete Turn- und Sportevents, verschiedene Restaurants und Bars sowie Konzerte und Aufführungen gilt es zu entdecken.
            &nbsp;&nbsp;
            <a
              href="https://harkanyfurdo.hu/de/strandbad/strandbad"
              target={target}
              rel={rel}
            >
             Mehr Informationen
            </a>{' '}
          </p>
        </GalleryWrapper>
        <Gallery images={harkany} />
      </InnerWrapper>
    </Wrapper>
  );
}

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  box-shadow: var(--bs2);
  border-radius: 10px;
  overflow-x: hidden;
  background: var(--clr-bg);
  padding: 50px 0 0 0;
  p {
    text-align: justify;
    color: white;
    padding: 25px;
  }
  a {
    color: white;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  h3 {
    padding: 0 25px;
  }
`;

const GalleryWrapper = styled.div`
  padding: ${({ isMobile }) => (isMobile ? '' : '25px')};
`;
